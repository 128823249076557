const config = {
    REACT_APP_API_SERVICE_GET_NOW_PLAYING_MOVIE_URL: process.env.REACT_APP_API_SERVICE_GET_NOW_PLAYING_MOVIE_URL,
    REACT_APP_API_SERVICE_GET_MOVIE_REVIEW_URL: process.env.REACT_APP_API_SERVICE_GET_MOVIE_REVIEW_URL,
    REACT_APP_API_SERVICE_GET_POPULAR_MOVIE_URL: process.env.REACT_APP_API_SERVICE_GET_POPULAR_MOVIE_URL,
    REACT_APP_API_SERVICE_GET_TREND_MOVIE_URL: process.env.REACT_APP_API_SERVICE_GET_TREND_MOVIE_URL,
    REACT_APP_API_SERVICE_GET_UPCOMING_MOVIE_URL: process.env.REACT_APP_API_SERVICE_GET_UPCOMING_MOVIE_URL,
    REACT_APP_API_SERVICE_GET_MOVIE_GENRE_URL: process.env.REACT_APP_API_SERVICE_GET_MOVIE_GENRE_URL,
    REACT_APP_API_SERVICE_GET_MOVIE_NAME_LIST_URL: process.env.REACT_APP_API_SERVICE_GET_MOVIE_NAME_LIST_URL,
    REACT_APP_API_SERVICE_GET_MOVIE_DETAILS_URL: process.env.REACT_APP_API_SERVICE_GET_MOVIE_DETAILS_URL,
    REACT_APP_API_SERVICE_GET_CAST_DETAILS_URL: process.env.REACT_APP_API_SERVICE_GET_CAST_DETAILS_URL,
    REACT_APP_API_SERVICE_GET_PRODUCTION_DETAILS_URL: process.env.REACT_APP_API_SERVICE_GET_PRODUCTION_DETAILS_URL,
    REACT_APP_API_SERVICE_GET_RECOMMENDATION_DETAILS_URL: process.env.REACT_APP_API_SERVICE_GET_RECOMMENDATION_DETAILS_URL,
  };
  
  
  export default config;
  